import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Hero from 'patronscan-theme/src/components/sections/hero';
import PrivacyCommitment from 'patronscan-theme/src/components/sections/privacy';
import FrequentlyAskedQuestions from 'patronscan-theme/src/components/sections/privacy/frequently-asked-questions';

const PrivacyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-images/privacy-hero-horizontal.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const questions = [
    {
      question: 'Open and transparent management of personal information',
      answer: `
<p>We only collect the following information and is limited to:</p>
<ul class="pl-8 list-disc mt-4"> 
<li>your name</li>
<li>your date of birth, or residential address, or both</li>
<li>the photograph appearing on your photo ID.</li>
</ul>
<p class="mt-4">All personal information is securely held and managed in a secure Australian data centre.</p>`
    },
    {
      question: 'Collection of personal information',
      answer: `<p>The ID scanner system does not collect or use unsolicited personal information.</p>`,
    },
    {
      question: 'Use or disclosure of personal information',
      answer: `
<p>Your personal information is not stored locally at any venue, and it cannot be accessed except as described below.</p>
<p class="mt-4">Group Security Solutions has access to the collected information for the purpose of maintaining the system and responding to lawful requests from:</p>
<ul class="pl-8 list-disc mt-4"> 
<li>Law Enforcement</li>
<li>Liquor Authority </li>
</ul>
<p class="mt-4">If you have been issued with a venue banning order your banning order may be shared with other connected venues, in which they may refuse you entry into the venue. No other information is shared with us.</p>`,
    },
    {
      question: 'Direct Marketing',
      answer: `
            <p>We do not have access to your personal information once it is scanned, and therefore we cannot use or disclose this information for the purpose of direct marketing.</p>`,
    },
    {
      question: 'Cross-border disclosure of personal information',
      answer: `
            <p>Your personal information is securely managed and held by Group Security, and by law we cannot transfer this information outside of Australia except to New Zealand.</p>`,
    },
    {
      question: 'Adoption, use or disclosure of government related identifiers',
      answer: `
            <p>Government issued identifiers such as passport numbers are not collected or used as part of the ID scanner system.</p>`,
    },
    {
      question: 'Security of personal information',
      answer: `
            <p>Data is stored in an encrypted form and is only accessible through the system software. All data communications are encrypted and your personal information is not stored in plain text.</p>
            <p class="mt-4">Venue staff do not have access to your personal information, and each scanner venue has a unique login ID, which is subject to an audit trail.</p>
            <p class="mt-4">Your personal information is automatically and permanently deleted from the ID scanner system 30 days after it was collected, except where:</p>
            <ul class="pl-8 list-disc mt-4"> 
            <li>you are subject to a long term banning order, in which case your information is kept for the duration of the banning period, or</li>
            <li>in such other cases at the request of Law Enforcement.</li>
            </ul>`,
    },
    {
      question: 'Is data given away or sold?',
      answer: `
            <p>No personal data is provided to third parties outside of law enforcement and venue staff. Again, unless a patron is flagged, data is permanently deleted shortly after visiting an establishment.</p>`,
    },
    {
      question: 'Contacts and Complaints',
      answer: `
            <p>If you believe we have breached your privacy, or if you want to raise any issues you may have about privacy at our venue, please contact us by the following methods:</p>
            <blockquote class="mb-8 mt-4">
            <p>Group Security (approved system provider):<br>
            Phone: 02 8556 6555<br>
            Email: <a class="text-blue" href="mailto:privacy@patronscan.com.au">privacy@patronscan.com.au</a><br>
            Web: <a class="text-blue" href="https://www.patronscan.com.au">https://www.patronscan.com.au</a></p>
            </blockquote>
            <p>In making a complaint to us about privacy, please give us enough details to be able to identify your concerns and respond appropriately. You must provide us with your name and contact details and a description of your complaint. We will respond to you in a reasonable time frame (usually not more than 30 days).</p>
            <p class="mt-4">We are also required by law to notify the Secretary, NSW Trade &amp; Investment, within 14 days of any written complaint by you about a breach of privacy.</p>
            <p>If you are unhappy with how we handle the complaint, you have a right to take the complaint to the Office of the Australian Information Commissioner.</p>`,
    },
  ];
  const auQuestions = [
    {
      question: 'General',
      answer: `<p>Group Security Solutions has access to the collected information for the purpose of maintaining the system and responding to lawful requests from:</p>
      <ul class="pl-8 list-disc mt-4"> 
      <li>the Police Force
      <li>the Ministry of Justice (and authorised officers within the Liquor Authority)
      <li>Reviewing a venue ban that you believe may have been issued without cause
      </ul>
      <p class="mt-4">If you have been issued with a temporary or long-term banning order, the system operator will alert us that you are not allowed to enter a connected venue. No other information is shared with us.</p>
      <p class="mt-4">If you have been issued with a venue banning order your banning order may be shared with other connected venues, in which they may refuse you entry into the venue`
    },
    {
      question: 'Data Retention',
      answer: `<p>Your personal information is automatically and permanently deleted from the ID scanner system 30 days after it was collected, except where:</p>
      <ul class="pl-8 list-disc mt-4"> 
      <li>you are subject to a long term banning order, in which case your information is kept for the duration of the banning period, or
      <li>in such other cases as authorised by the Secretary, NSW Trade & Investment, at the request of the Commissioner of Police.
      </ul>`,
    },
    {
      question: 'Contacts and Complaints',
      answer: 
      `<p>If you believe we have breached your privacy, or if you want to raise any issues you may have about privacy at our venue, please contact us by the following methods:</p>
      <blockquote class="mb-8 mt-4">
      <p>Group Security (approved system provider):<br>
          Phone: 02 8556 6555<br>
          Email: <a class="text-blue" href="mailto:privacy@patronscan.com.au">privacy@patronscan.com.au</a><br>
          Web: <a class="text-blue" href="https://www.patronscan.com.au">https://www.patronscan.com.au</a></p>
      </blockquote>
      <p class="mt-4">In making a complaint to us about privacy, please give us enough details to be able to identify your concerns and respond appropriately. You must provide us with your name and contact details and a description of your complaint. We will respond to you in a reasonable time frame (usually not more than 30 days).</p>
      <p class="mt-4">We are also required by law to notify the Secretary, NSW Trade & Investment, within 14 days of any written complaint by you about a breach of privacy.</p>
      <p class="mt-4">If you are unhappy with how we handle the complaint, you have a right to take the complaint to the Office of the Australian Information Commissioner.</p>
      <blockquote class="mb-8 mt-4">
          Phone: 1300 363 992<br>
          Email: <a class="text-blue" href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a><br>
          Web: <a class="text-blue" href="https://www.oaic.gov.au" target="_blank">https://www.oaic.gov.au</a>
      </blockquote>`,
    },
  ];
  const nzQuestions = [
    {
      question: 'General',
      answer: 
      `<p>Group Security Solutions has access to the collected information for the purpose of maintaining the system and responding to lawful requests from:</p>
      <ul class="pl-8 list-disc mt-4"> 
      <li>the Police Force
      <li>the Ministry of Justice (and authorised officers within the Liquor Authority)
      <li>Reviewing a venue ban that you believe may have been issued without cause
      </ul>
      <p class="mt-4">If you have been issued with a temporary or long-term banning order, the system operator will alert us that you are not allowed to enter a connected venue. No other information is shared with us.</p>
      <p class="mt-4">If you have been issued with a venue banning order your banning order may be shared with other connected venues, in which they may refuse you entry into the venue</p>`,
    },
    {
      question: 'Data Retention',
      answer: 
      `<p>Your personal information is automatically and permanently deleted from the ID scanner system 90 days after it was collected, except where:</p>
      <ul class="pl-8 list-disc mt-4"> 
      <li>you are subject to a banning order, in which case your information is kept for the duration of the banning period, or
      <li>in such other cases as authorised at the request of the New Zealand Police.
      </ul>`
    },
    {
      question: 'Cross-border disclosure of personsal information',
      answer: 
      `<p>Your personal information is securely held and processed on behalf of the venue by our system provider, Group Security Solutions Trading as Patronscan which is held in Sydney Australia and is subject to laws that provide comparable safeguards to the New Zealand Privacy Act.</p>`
    },
    {
      question: 'Contacts and Complaints',
      answer: 
      `<p>If you believe we have breached your privacy, or if you want to raise any issues you may have about privacy at our venue, please contact us by the following methods:</p>
      <blockquote class="mb-8 mt-4">
      <p>Group Security (approved system provider):<br>
        Phone: 02 8556 6555<br>
        Email: <a class="text-blue" href="mailto:privacy@patronscan.com.au">privacy@patronscan.com.au</a><br>
        Web: <a class="text-blue" href="http://au.patronscan.com">http://au.patronscan.com</a></p>
      </blockquote>
      <p>In making a complaint to us about privacy, please give us enough details to be able to identify your concerns and respond appropriately. You must provide us with your name and contact details and a description of your complaint. We will respond to you in a reasonable time frame (usually not more than 30 days).</p>
      <p>If you are unhappy with how we handle the complaint, you have a right to take the complaint to the Office of the Privacy Commissioner.</p>
      <blockquote class="mb-8 mt-4">
        Phone: 0800 803 909<br>
        Email: <a class="text-blue" href="mailto:enquiries@privacy.org.nz">enquiries@privacy.org.nz</a><br>
        Web: <a class="text-blue" href="https://www.privacy.org.nz" target="_blank">https://www.privacy.org.nz</a>
      </blockquote>`,
    },
  ];
  return (
    <div>
      <Hero
        images={data.file.childImageSharp.fluid}
        heading="Protecting People - Protecting Privacy"
        subHeading="Patronscan aims to make establishments safer for patrons and to create trustworthy relationships. This is why our type of technology is endorsed by small business owners, crime victim organizations, neighborhood associations, entertainment districts, local government and law enforcement agencies."
      />
      <FrequentlyAskedQuestions questions={questions} />
      <PrivacyCommitment showCta={false} bgColor="#FAFAFA" />
      <FrequentlyAskedQuestions title='Australia' subTitle='Your personal information is not stored locally at any venue, and it cannot be accessed except as described below.' questions={auQuestions} />
      <FrequentlyAskedQuestions title="New Zealand" subTitle='Your personal information is not stored locally at any venue, and it cannot be accessed except as described below.' questions={nzQuestions} />
    </div>
  );
};

export default PrivacyPage;
