import { checkPropTypes } from 'prop-types';
import React, { FC } from 'react';
import { Question } from '../../../models/question';
import Button from '../../buttons/button';

interface FrequentlyAskedQuestionsProps {
  questions: Question[];
  subTitle?: string;
  title?: string;
  showButtons?: boolean;
}

const FrequentlyAskedQuestions: FC<FrequentlyAskedQuestionsProps> = (props) => {
  const createMarkup = (html: string) => {
    return { __html: html };
  };

  return (
    <section id="questions" className="my-16">
      <div className="p-4 max-w-container mx-auto">
        {props.title ? (
          <h2 className="text-4xl font-heading font-medium text-center mb-10">{props.title}</h2>
        ) : (
          <h2 className="text-4xl font-heading font-medium text-center mb-10">
            Frequently Asked Questions
          </h2>
        )}
        {props.subTitle ? <h2 className="mb-2 text-left">{props.subTitle}</h2> : <></>}
        <div className="flex flex-row flex-wrap">
          {props.questions.map((question: Question) => (
            <div key={question.question} className="my-5">
              <h2 className="text-2xl font-hairline text-blue-lighter mb-3">{question.question}</h2>
              <div dangerouslySetInnerHTML={createMarkup(question.answer)}></div>
            </div>
          ))}
          <div className="block w-full md:w-auto inline-flex flex-wrap justify-center md:justify-start p-4">
            {props.showButtons ? (
              <>
                <Button
                  buttonId="FlagDispute"
                  className="mr-2 md:mr-8 w-full"
                  blue
                  text="I want to dispute a flag"
                  link="https://dispute.patronscan.com/investigation-request/"
                />
                <Button
                  buttonId="CheckInfo"
                  className="ml-2 md:ml-8 w-full"
                  blue
                  text="I want to check my info"
                  link="https://dispute.patronscan.com/disclosure-request/"
                />{' '}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrequentlyAskedQuestions;
